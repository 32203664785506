import {BaseListStore} from "app/modules/common/stores/BaseListStore";
import {action, makeObservable, runInAction} from "mobx";
import {inject} from "react-ioc";
import {AdminMenu, AdminStore} from "app/modules/main/components/admin/store/AdminStore";
import {
    InventoryAssignmentListModel, InventoryAssignmentListFilters, DataListGetParamsOfInventoryAssignmentListFilters,
} from "app/modules/main/api/admin-clients.api";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {DialogStockChange} from "app/modules/main/components/admin/inventory/stock/dialog.stock.change";
import React from "react";
import {DialogStore} from "app/modules/common/dialog/DialogStore";

export class ListStore extends BaseListStore<InventoryAssignmentListModel>{
    @inject apiClients: ApiClients;
    @inject adminStore: AdminStore;
    @inject dialogStore: DialogStore;

    filters = new FormState({
        search: new FieldState('')
    })

    constructor() {
        super();

        makeObservable(this, {
            init: action
        });
    }

    applySpecificFilters() { }

    adjustStock = (inventoryId: number, currentStock: number, isAdd: boolean) => {
        const key = "adjust_stock";
        this.dialogStore.addDialog({
            key: key,
                component: <DialogStockChange closeModal={() => this.dialogStore.closeDialog(key)}
                                              stockUpdated={() => {
                                                  this.dialogStore.closeDialog(key);
                                                  this.loadData();
                                              }}
                                              currentStock={currentStock}
                                              isAdd={isAdd}
                                              inventoryId={inventoryId}/>
        });
    }

    async loadData() {
        this.wrapLoadingApiCall(async () => {
            let filters = new InventoryAssignmentListFilters({ search: this.filters.$.search.value });
            const [result] = await Promise.all([
                this.apiClients.inventoryAssignmentClient.getList(new DataListGetParamsOfInventoryAssignmentListFilters({
                    offset: this.skipRows,
                    pageSize: this.pageSize,
                    filter: filters
                }))
            ]);

            this.items = result.items;
            this.totalRows = result.count;
        });
    }

    async init() {
        this.adminStore.selectMenu(AdminMenu.InventoryAssignments);

        await this.loadData();

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
