import {Component} from "react";
import React from "react";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {Button, Grid, InputBase} from "@mui/material";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Loading} from "app/modules/common/layout/components/loading";
import {inject, provider} from "react-ioc";
import {ListStore} from "app/modules/main/components/admin/inventory/assignments/ListStore";
import {observer} from "mobx-react";
import {InventoryAssignmentListModel} from "app/modules/main/api/admin-clients.api";
import {Link} from "react-router-dom";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {StringHelper} from "app/modules/common/helpers/StringHelper";

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        maxWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InventoryAssignmentListModel>) => <Link to={"/admin/inventory-assignments/" + (params.row as InventoryAssignmentListModel).id}>
            {(params.row as InventoryAssignmentListModel).id}
        </Link>,
    },
    {
        field: 'name',
        headerName: 'Name',
        maxWidth: 250,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InventoryAssignmentListModel>) => <Link to={"/admin/inventory-assignments/" + (params.row as InventoryAssignmentListModel).id}>
            {(params.row as InventoryAssignmentListModel).name}
        </Link>,
    },
    {
        field: 'category',
        headerName: 'Category',
        maxWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InventoryAssignmentListModel>) => (params.row as InventoryAssignmentListModel).category,
    },
    {
        field: 'inventoryAssignmentsType',
        headerName: 'Assignment Type',
        maxWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InventoryAssignmentListModel>) => (params.row as InventoryAssignmentListModel).inventoryAssignmentsType,
    },
    {
        field: 'intervalType',
        headerName: 'Interval',
        maxWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InventoryAssignmentListModel>) => {
            let row = (params.row as InventoryAssignmentListModel);

            return !!row.intervalType ? `Interval: ${row.replaceInterval} (${row.intervalType})` : "None"
        },
    },
    {
        field: 'qty',
        headerName: 'Qty',
        maxWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InventoryAssignmentListModel>) => (params.row as InventoryAssignmentListModel).qty,
    },
    {
        field: 'itemName',
        headerName: 'Inventory',
        maxWidth: 250,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InventoryAssignmentListModel>) => (params.row as InventoryAssignmentListModel).itemName,
    },
    {
        field: 'addForAllProperties',
        headerName: 'For All Props?',
        maxWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InventoryAssignmentListModel>) => StringHelper.formatYesNo((params.row as InventoryAssignmentListModel).addForAllProperties),
    },
];

@provider(ListStore)
@observer
export class Index extends Component<any, any> {
    @inject listStore: ListStore;

    render() {
        if(this.listStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={6}>
                <PageTitleChip Title={"Inventory Assignments"} TotalRows={this.listStore.totalRows}/>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
                <BtnLink Link={"/admin/inventory-assignments/new"} Text="New Inventory Assignment"/>
            </Grid>
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.listStore.filters.$.search.value}
                            onChange={(e)=>this.listStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                            onKeyUp={(e) => e.keyCode == 13 && this.listStore.applyFilters()}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.listStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.listStore.items}
                    columns={columns}
                    paginationModel={{ page: this.listStore.skipRows/this.listStore.pageSize, pageSize: this.listStore.pageSize }}
                    pageSizeOptions={this.listStore.pageSizeOptions}
                    rowCount={this.listStore.totalRows}
                    loading={this.listStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    rowSelection={false}
                    checkboxSelection={false}
                    onPaginationModelChange={(model) => this.listStore.setPage(model.page, model.pageSize)}/>
            </DataGridBody>
        </DataGridContainer>;
    }

    async componentDidMount() {
        this.listStore.init();
    }
}
