import {Component} from "react";
import React from "react";
import {Loading} from "app/modules/common/layout/components/loading";
import {inject, provider} from "react-ioc";
import {ListStore} from "app/modules/main/components/admin/inventory/locations/ListStore";
import {observer} from "mobx-react";
import {Box, Button, Divider, Grid, InputBase, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {BoxCenter, BoxCenterLeft} from "app/modules/common/layout/components/containers";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";
import {propertyStatusColorMap} from "app/modules/main/components/admin/properties/list";
import {DateTimeHelper} from "app/modules/common/helpers/DateTimeHelper";
import moment from "moment";
import WarningIcon from '@mui/icons-material/Warning';

@provider(ListStore)
@observer
export class Index extends Component<any, any> {
    @inject store: ListStore;

    render() {
        if(this.store.isInitLoading) {
            return <Loading />;
        }

        return <Box>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <nav aria-label="main mailbox folders">
                        <BoxCenter>
                            <SearchPaper width={400}>
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    value={""}
                                    onChange={(e) => {}}
                                    placeholder="Search"
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                <SearchVerDivider />
                                <Button variant="text" onClick={() => {}}>Search</Button>
                            </SearchPaper>
                        </BoxCenter>
                        <List sx={{ height: "calc(100vh - 230px)", overflowY: "auto", mt: 2 }}>
                            {this.store.properties?.map((x, index) => {
                                return <ListItem disablePadding key={x.id}>
                                    <ListItemButton selected={this.store.propertyId == x.id} onClick={() => this.store.selectProperty(x.id)}>
                                        <Box sx={{ width: "5px", height: "25px", backgroundColor: propertyStatusColorMap.get(x.status) }} />
                                        <ListItemText sx={{ paddingLeft: "5px" }} primary={x.name} />
                                    </ListItemButton>
                                </ListItem>;
                            })}
                        </List>
                    </nav>
                </Grid>
                <Grid item xs={10}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box display="flex"
                                 justifyContent="right"
                                 alignItems="center">
                                <Button sx={{ mr: 4, height: "34px" }} variant={"contained"} color={"secondary"} onClick={() => this.store.addItem()}>Update Stock/Stocktaking</Button>
                                <Button sx={{ mr: 4, height: "34px" }} variant={"contained"} color={"primary"} onClick={() => this.store.addItem()}>Add Assignment</Button>
                                <Button sx={{ mr: 4, height: "34px" }} variant={"contained"} color={"warning"} onClick={() => this.store.addItems()}>Add All Assignments</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ pt: "2px", pb: "2px", pl: 1, pr: 1, fontSize: "14px" }}>
                            <Grid container>
                                <Grid item xs={1}>
                                    <b>Qty (Required Qty)</b>
                                </Grid>
                                <Grid item xs={3}>
                                    <b>Inv Name (Assigment)</b>
                                </Grid>
                                <Grid item xs={1}>
                                    <b>Category</b>
                                </Grid>
                                <Grid item xs={1}>
                                    <b>Replace On</b>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container sx={{ pt: 1, pb: 1, fontSize: "14px" }}>
                                { this.store.inventories?.length > 0 ? this.store.inventories.map((x, index) => {
                                    let needToReplace = x.replaceOn && x.replaceOn < moment();
                                    let needToRefill = x.actualQty < x.requiredQty
                                    let cssClass = needToRefill || needToReplace ? "DataGridPro-negative-row" : "";

                                    return <Grid container key={x.id}>
                                        <Grid item xs={12} className={cssClass} sx={{ pt: "2px", pb: "2px", pl: 1, pr: 1 }}>
                                            <Grid container>
                                                <Grid item xs={1}>
                                                    <BoxCenterLeft>
                                                        <b>{x.actualQty}</b> ({x.requiredQty}){needToRefill && <WarningIcon fontSize={"small"} color={"warning"}/>}
                                                    </BoxCenterLeft>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <b>{x.inventoryName}</b> ({x.inventoryAssignmentName})
                                                </Grid>
                                                <Grid item xs={1}>
                                                    {x.category}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <BoxCenterLeft>
                                                        {x.replaceOn && DateTimeHelper.formatMomentDate(x.replaceOn)}{needToReplace && <WarningIcon fontSize={"small"} color={"warning"}/>}
                                                    </BoxCenterLeft>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {index + 1 < this.store.inventories?.length && <Grid item xs={12}><Divider sx={{ mb: "1px", pt: "1px" }}/></Grid>}
                                    </Grid>;
                                }) : <BoxCenter>No items</BoxCenter>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>;
    }

    async componentDidMount() {
        this.store.init();
    }
}
