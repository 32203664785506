import {Component} from "react";
import React from "react";
import {GridRenderCellParams} from "@mui/x-data-grid";
import {DataGridContainer} from "app/modules/common/grid/data-grid-container";
import {Box, Button, Grid, IconButton, InputBase, Stack} from "@mui/material";
import {PageTitleChip} from "app/modules/common/layout/components/page-title";
import {DataGridBody} from "app/modules/common/grid/data-grid-body";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Loading} from "app/modules/common/layout/components/loading";
import {inject, provider, useInstance} from "react-ioc";
import {ListStore} from "app/modules/main/components/admin/inventory/stock/ListStore";
import {observer} from "mobx-react";
import {InventoryItemListModel} from "app/modules/main/api/admin-clients.api";
import {Link} from "react-router-dom";
import {BtnLink} from "app/modules/common/layout/components/btn-link";
import {NumberHelper} from "app/modules/common/helpers/NumberHelper";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {BoxCenter} from "app/modules/common/layout/components/containers";
import {GridRowClassNameParams} from "@mui/x-data-grid/models/params";
import {DataGridHeader} from "app/modules/common/grid/data-grid-header";
import {SearchPaper, SearchVerDivider} from "app/modules/common/layout/components/search";

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        maxWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InventoryItemListModel>) => <Link to={"/admin/inventory/" + (params.row as InventoryItemListModel).id}>
            {(params.row as InventoryItemListModel).id}
        </Link>,
    },
    {
        field: 'name',
        headerName: 'Name',
        maxWidth: 250,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InventoryItemListModel>) => <Link to={"/admin/inventory/" + (params.row as InventoryItemListModel).id}>
            {(params.row as InventoryItemListModel).name}
        </Link>,
    },
    {
        field: 'price',
        headerName: 'Price',
        maxWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InventoryItemListModel>) => NumberHelper.formatUSDollar((params.row as InventoryItemListModel).price),
    },
    {
        field: 'stockQty',
        headerName: 'Stock',
        maxWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InventoryItemListModel>) => (params.row as InventoryItemListModel).stockQty,
    },
    {
        field: 'minLevel',
        headerName: 'Min Stock',
        maxWidth: 200,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InventoryItemListModel>) => (params.row as InventoryItemListModel).minLevel,
    },
    {
        field: 'productLink',
        headerName: 'Product Link',
        maxWidth: 250,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InventoryItemListModel>) =>(params.row as InventoryItemListModel).productLink?.length > 0 ? <Stack spacing={2} direction={"row"}>
            <a href={(params.row as InventoryItemListModel).productLink} target={"_blank"}>
                Link
            </a>
        </Stack> : <Box>N/A</Box>
    },
    {
        field: 'actions',
        headerName: 'Edit Stock Qty',
        maxWidth: 250,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InventoryItemListModel>) => {
            const store = useInstance(ListStore);

            return <Stack spacing={2} direction={"row"}>
                <IconButton color="primary" title={"Add To Stock"} onClick={() => store.adjustStock((params.row as InventoryItemListModel).id, (params.row as InventoryItemListModel).stockQty, true)}>
                    <AddIcon fontSize={"small"} />
                </IconButton>
                <IconButton color="error" title={"Remove From Stock"} onClick={() => store.adjustStock((params.row as InventoryItemListModel).id, (params.row as InventoryItemListModel).stockQty, false)}>
                    <RemoveIcon fontSize={"small"} />
                </IconButton>
            </Stack>;
        }
    },
    {
        field: 'logs',
        headerName: 'Logs',
        maxWidth: 250,
        flex: 1,
        renderCell: (params: GridRenderCellParams<InventoryItemListModel>) => {

            return <Stack spacing={2} direction={"row"}>
                <BoxCenter>
                    <Link to={"/inventory/" + (params.row as InventoryItemListModel).id}>
                        Logs
                    </Link>
                </BoxCenter>
            </Stack>;
        }
    }
];

@provider(ListStore)
@observer
export class Index extends Component<any, any> {
    @inject listStore: ListStore;

    render() {
        if(this.listStore.isInitLoading) {
            return <Loading />;
        }

        return <DataGridContainer>
            <Grid item xs={6}>
                <PageTitleChip Title={"Inventory"} TotalRows={this.listStore.totalRows}/>
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
                <BtnLink Link={"/admin/inventory/new"} Text="New Inventory"/>
            </Grid>
            <DataGridHeader>
                <BoxCenter>
                    <SearchPaper width={400}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={this.listStore.filters.$.search.value}
                            onChange={(e)=>this.listStore.filters.$.search.onChange(e.target.value)}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                            onKeyUp={(e) => e.keyCode == 13 && this.listStore.applyFilters()}
                        />
                        <SearchVerDivider />
                        <Button variant="text" onClick={this.listStore.applyFilters}>Search</Button>
                    </SearchPaper>
                </BoxCenter>
            </DataGridHeader>
            <DataGridBody>
                <DataGridPro
                    disableVirtualization
                    rows={this.listStore.items}
                    columns={columns}
                    paginationModel={{ page: this.listStore.skipRows/this.listStore.pageSize, pageSize: this.listStore.pageSize }}
                    pageSizeOptions={this.listStore.pageSizeOptions}
                    rowCount={this.listStore.totalRows}
                    loading={this.listStore.isLoading}
                    pagination
                    paginationMode="server"
                    disableColumnFilter
                    disableColumnSelector
                    getRowClassName={(params: GridRowClassNameParams<InventoryItemListModel>) => (params.row as InventoryItemListModel).stockQty < (params.row as InventoryItemListModel).minLevel ? "DataGridPro-negative-row" : ""}
                    rowSelection={false}
                    checkboxSelection={false}
                    onPaginationModelChange={(model) => this.listStore.setPage(model.page, model.pageSize)}/>
            </DataGridBody>
        </DataGridContainer>;
    }

    async componentDidMount() {
        this.listStore.init();
    }
}
