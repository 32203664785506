import {provider, useInstance} from "react-ioc";
import {DetailsStore} from "app/modules/main/components/admin/inventory/stock/details/DetailsStore";
import {observer} from "mobx-react";
import {Navigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {AdminMenu, AdminStore} from "app/modules/main/components/admin/store/AdminStore";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {LoadingButton} from "@mui/lab";
import {Grid, MenuItem, Stack, Typography} from "@mui/material";
import {Section, SectionName} from "app/modules/common/layout/components/details";
import {
    SectionNumberTextField, SectionSelectField, SectionTextAreaField,
    SectionTextField
} from "app/modules/common/details/text-field";
import {Loading} from "app/modules/common/layout/components/loading";

export default provider(DetailsStore)(observer(() => {
    let store = useInstance(DetailsStore);
    let adminStore = useInstance(AdminStore);

    const urlParams = useParams();
    const { id } = urlParams;

    useEffect(
        () => {
            store.init(!!id && parseInt(id) || null);
            adminStore.selectMenu(AdminMenu.InventoryStock);
        },
        [id],
    );

    if(store.isSavedSuccessfully) {
        return <Navigate to={"/admin/inventory/" + store.inventoryItemId} replace={true} />;
    }

    if(store.isInitLoading) {
        return <Loading />;
    }

    return <Container>
        <PageTitleDetails Title={store.name || "New Inventory"} Size={8}/>
        <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
            <LoadingButton loading={store.isLoading} disabled={store.formState.hasError} variant="contained" onClick={store.save}>
                Save
            </LoadingButton>
        </Grid>
        <ContainerDetails>
            <SectionName title="INFO" description="" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"Name"}
                            field={store.formState.$.name}
                            width={"50%"}
                        />
                        <SectionNumberTextField
                            label={"Price"}
                            field={store.formState.$.price}
                            width={"50%"}
                            prefix="$"
                        />
                    </Stack>
                    <Stack spacing={2} direction={"row"}>
                        <SectionNumberTextField
                            label={"Min Level"}
                            field={store.formState.$.minLevel}
                            width={"50%"}
                        />
                        <SectionNumberTextField
                            label={store.inventoryItemId > 0 ? "Stock Qty (Readonly)" : "Stock Qty"}
                            field={store.formNewState.$.stockQty}
                            width={"50%"}
                            isReadOnlyMode={store.inventoryItemId > 0}
                        />
                    </Stack>
                    <SectionSelectField
                        field={store.formNewState.$.unitOfMeasurement}
                        width={"100%"}
                        label={store.inventoryItemId > 0 ? "Unit Of Measurement (Readonly)" : "Unit Of Measurement"}
                        isReadOnlyMode={store.inventoryItemId > 0}
                        items={() => Array.from(store.unitOfMeasurementType.keys()).map(x => <MenuItem value={x} key={x}>{store.unitOfMeasurementType.get(x)}</MenuItem>)}
                    />
                    <SectionTextField
                        label={"Product Link"}
                        field={store.formState.$.productLink}
                        width={"100%"}
                    />
                    <Typography variant={"subtitle1"}>Notes</Typography>
                    <SectionTextAreaField
                        label={"Notes"}
                        field={store.formState.$.notes}
                        fullWidth
                    />
                </Stack>
            </Section>
        </ContainerDetails>
    </Container>;
}));
