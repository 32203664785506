import {provider, useInstance} from "react-ioc";
import {DetailsStore} from "app/modules/main/components/admin/inventory/assignments/details/DetailsStore";
import {observer} from "mobx-react";
import {Navigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {AdminMenu, AdminStore} from "app/modules/main/components/admin/store/AdminStore";
import {Container, ContainerDetails} from "app/modules/common/layout/components/containers";
import {PageTitleDetails} from "app/modules/common/layout/components/page-title";
import {LoadingButton} from "@mui/lab";
import {Grid, MenuItem, Stack} from "@mui/material";
import {Section, SectionName} from "app/modules/common/layout/components/details";
import {
    SectionCheckBoxField,
    SectionNumberTextField, SectionSelectField,
    SectionTextField
} from "app/modules/common/details/text-field";
import {Loading} from "app/modules/common/layout/components/loading";
import {InventoryAssignmentType} from "app/modules/main/api/admin-clients.api";
import {MainStore} from "app/modules/main/stores/MainStore";

export default provider(DetailsStore)(observer(() => {
    let store = useInstance(DetailsStore);
    let adminStore = useInstance(AdminStore);
    let mainStore = useInstance(MainStore);

    const urlParams = useParams();
    const { id } = urlParams;

    useEffect(
        () => {
            store.init(!!id && parseInt(id) || null);
            adminStore.selectMenu(AdminMenu.InventoryAssignments);
        },
        [id],
    );

    if(store.isSavedSuccessfully) {
        return <Navigate to={"/admin/inventory-assignments/" + store.inventoryItemId} replace={true} />;
    }

    if(store.isInitLoading) {
        return <Loading />;
    }

    return <Container>
        <PageTitleDetails Title={store.name || "New Inventory"} Size={8}/>
        <Grid item xs={4} sx={{ pb: 4 }} textAlign={"right"}>
            <LoadingButton loading={store.isLoading} disabled={store.formState.hasError} variant="contained" onClick={store.save}>
                Save
            </LoadingButton>
        </Grid>
        <ContainerDetails>
            <SectionName title="INFO" description="" />
            <Section>
                <Stack spacing={2} direction={"column"}>
                    <Stack spacing={2} direction={"row"}>
                        <SectionTextField
                            label={"Name"}
                            field={store.formState.$.name}
                            width={"50%"}
                        />
                        <SectionSelectField
                            field={store.formState.$.category}
                            width={"100%"}
                            label={"Category"}
                            items={() => Array.from(store.inventoryItemCategoryTypes.keys()).map(x => <MenuItem value={x} key={x}>{store.inventoryItemCategoryTypes.get(x)}</MenuItem>)}
                        />
                    </Stack>
                    <Stack spacing={2} direction={"row"}>
                        <SectionNumberTextField
                            label={"Qty"}
                            field={store.formState.$.qty}
                            width={"50%"}
                        />
                        <SectionCheckBoxField
                            field={store.formState.$.addForAllProperties}
                            label="Add For All Properties"
                            width={"50%"}
                        />
                    </Stack>
                    <Stack spacing={2} direction={"row"}>
                        <SectionNumberTextField
                            label={"Replace Interval"}
                            field={store.formState.$.replaceInterval}
                            width={"50%"}
                        />
                        <SectionSelectField
                            field={store.formState.$.intervalType}
                            width={"50%"}
                            label={"Replace Interval Type"}
                            items={() => Array.from(store.inventoryAssignmentIntervalTypes.keys()).map(x => <MenuItem value={x} key={x}>{store.inventoryAssignmentIntervalTypes.get(x)}</MenuItem>)}
                        />
                    </Stack>
                    <SectionSelectField
                        field={store.formNewState.$.inventoryAssignmentsType}
                        width={"100%"}
                        label={store.inventoryItemId > 0 ? "Assignments Type (Readonly)" : "Assignments Type"}
                        isReadOnlyMode={store.inventoryItemId > 0}
                        items={() => Array.from(store.inventoryAssignmentTypes.keys()).map(x => <MenuItem value={x} key={x}>{store.inventoryAssignmentTypes.get(x)}</MenuItem>)}
                    />
                    { store.formNewState.$.inventoryAssignmentsType.value == InventoryAssignmentType.PerBedType && <SectionSelectField
                        field={store.formNewState.$.inventoryAssignmentsBedType}
                        width={"100%"}
                        label={store.inventoryItemId > 0 ? "Bed Type (Readonly)" : "Bed Type"}
                        isReadOnlyMode={store.inventoryItemId > 0}
                        items={() => mainStore.clientData.propertySleepingArrangementBedTypes.map((x) => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                    />}
                    <SectionSelectField
                        field={store.formNewState.$.itemId}
                        width={"100%"}
                        label={store.inventoryItemId > 0 ? "Inventory Item (Readonly)" : "Inventory Item"}
                        isReadOnlyMode={store.inventoryItemId > 0}
                        items={() => store.inventoryShortList.map(x => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)}
                    />
                </Stack>
            </Section>
        </ContainerDetails>
    </Container>;
}));
