import {action, makeObservable, observable, runInAction} from "mobx";
import {inject} from "react-ioc";
import {AdminMenu, AdminStore} from "app/modules/main/components/admin/store/AdminStore";
import {
    PropertyInventoryAddAllModel,
    PropertyInventoryListModel, PropertyInventoryNewModel,
    PropertyListFilters,
    PropertyShortListModel,
} from "app/modules/main/api/admin-clients.api";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {DialogStore} from "app/modules/common/dialog/DialogStore";
import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";

export class ListStore extends BaseLoadingStore{
    properties: PropertyShortListModel[] = null;
    propertyId: number = null;

    inventories: PropertyInventoryListModel[] = null;

    @inject apiClients: ApiClients;
    @inject adminStore: AdminStore;
    @inject dialogStore: DialogStore;

    filters = new FormState({
        search: new FieldState('')
    })

    constructor() {
        super();

        makeObservable(this, {
            properties: observable,
            propertyId: observable,
            inventories: observable,

            init: action,
            selectProperty: action
        });
    }

    selectProperty = async (propertyId) => {
        if(this.propertyId != propertyId) {
            this.propertyId = propertyId;
            await this.wrapLoadingApiCall(async () => {
                await this.loadData();
            });
        }
    }

    async loadData() {
        await this.wrapLoadingApiCall(async () => {
            [this.inventories] = await Promise.all([
                this.apiClients.propertyInventoryClient.getListByPropertyId(this.propertyId)
            ]);
        });
    }

    async addItem() {
        //inventoryAssignmentId: number
        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.propertyInventoryClient.addItem(new PropertyInventoryNewModel({
                propertyId: this.propertyId,
                inventoryAssignmentId: 1//inventoryAssignmentId
            }));

            await this.loadData();
        });
    }

    async addItems() {
        await this.wrapLoadingApiCall(async () => {
            await this.apiClients.propertyInventoryClient.addItems(new PropertyInventoryAddAllModel({
                propertyId: this.propertyId
            }));

            await this.loadData();
        });
    }

    async init() {
        this.adminStore.selectMenu(AdminMenu.InventoryLocations);

        await this.wrapInitLoadingApiCall(async () => {
            [this.properties] = [
                await this.apiClients.adminPropertyClient.getShortList(new PropertyListFilters({}))
            ];

            if(this.properties?.length > 0) {
                this.propertyId = this.properties[0].id;

                await this.loadData();
            }
        })

        runInAction(() => {
            this.isInitLoading = false;
        })
    }
}
