import React, {useEffect} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import {Index as ListIndex} from "app/modules/main/components/admin/properties/list/index";
import {Index as RequestsIndex} from "app/modules/main/components/admin/requests/list/index";
import DetailsIndex from "app/modules/main/components/admin/properties/details/index";
import BooksIndex from "app/modules/main/components/admin/books/timeline/index";
import IntegrationReservationIndex from "app/modules/main/components/admin/integration/reservation/index";
import CalendarIndex from "app/modules/main/components/admin/calendar/index";
import {Box, Card, CssBaseline, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {observer} from "mobx-react";
import {AdminMenu, AdminStore} from "app/modules/main/components/admin/store/AdminStore";
import {provider, useInstance} from "react-ioc";
import {Loading} from "app/modules/common/layout/components/loading";

import {Index as ListContacts} from "app/modules/main/components/admin/contacts/index";
import DetailsContacts from "app/modules/main/components/admin/contacts/details/index";

import {Index as ListPolicies} from "app/modules/main/components/admin/policies/index";
import DetailsPolicies from "app/modules/main/components/admin/policies/details/index";

import {Index as ListAmenities} from "app/modules/main/components/admin/amenities/index";
import DetailsAmenities from "app/modules/main/components/admin/amenities/details/index";

import {Index as ListPromoCodes} from "app/modules/main/components/admin/promo-codes/index";
import DetailsPromoCodes from "app/modules/main/components/admin/promo-codes/details/index";

import {Index as ListInventory} from "app/modules/main/components/admin/inventory/stock/index";
import DetailsInventory from "app/modules/main/components/admin/inventory/stock/details/index";

import {Index as ListInventoryAssignments} from "app/modules/main/components/admin/inventory/assignments/index";
import DetailsInventoryAssignments from "app/modules/main/components/admin/inventory/assignments/details/index";

import {Index as ListLocationInventory} from "app/modules/main/components/admin/inventory/locations/index";

const drawerWidth = 240;

export default provider(AdminStore)(observer(() => {
    let navigation = useNavigate();
    let store = useInstance(AdminStore);

    useEffect(
        () => {
            store.init();
        }, []);

    if(store.isInitLoading) {
        return <Loading />
    }

    return <Box sx={{ display: 'flex', height: "100%" }}>
        <CssBaseline />
        <Box
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
            <Card sx={{ height: "100%", borderRadius: 0 }}>
                <List>
                    <ListItem disablePadding selected={store.selectedMenu == AdminMenu.Properties}>
                        <ListItemButton onClick={() => navigation("/admin")}>
                            <ListItemText primary="Properties" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding selected={store.selectedMenu == AdminMenu.Books}>
                        <ListItemButton onClick={() => navigation("/admin/books")}>
                            <ListItemText primary="Books" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding selected={store.selectedMenu == AdminMenu.Calendar}>
                        <ListItemButton onClick={() => navigation("/admin/calendar")}>
                            <ListItemText primary="Calendar" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding selected={store.selectedMenu == AdminMenu.Requests}>
                        <ListItemButton onClick={() => navigation("/admin/requests")}>
                            <ListItemText primary="Requests" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding selected={store.selectedMenu == AdminMenu.Integration}>
                        <ListItemButton onClick={() => navigation("/admin/integration-reservations")}>
                            <ListItemText primary="Integrations" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding selected={store.selectedMenu == AdminMenu.Contacts}>
                        <ListItemButton onClick={() => navigation("/admin/contacts")}>
                            <ListItemText primary="Contacts" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding selected={store.selectedMenu == AdminMenu.Policies}>
                        <ListItemButton onClick={() => navigation("/admin/policies")}>
                            <ListItemText primary="Policies" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding selected={store.selectedMenu == AdminMenu.PropertyAmenity}>
                        <ListItemButton onClick={() => navigation("/admin/property-amenities")}>
                            <ListItemText primary="Amenities" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding selected={store.selectedMenu == AdminMenu.PromoCodes}>
                        <ListItemButton onClick={() => navigation("/admin/promo-codes")}>
                            <ListItemText primary="Promo Codes" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding selected={store.selectedMenu == AdminMenu.InventoryStock}>
                        <ListItemButton onClick={() => navigation("/admin/inventory")}>
                            <ListItemText primary="Inventory (Stock)" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding selected={store.selectedMenu == AdminMenu.InventoryAssignments}>
                        <ListItemButton onClick={() => navigation("/admin/inventory-assignments")}>
                            <ListItemText primary="Inventory (Assignments)" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding selected={store.selectedMenu == AdminMenu.InventoryLocations}>
                        <ListItemButton onClick={() => navigation("/admin/inventory-location")}>
                            <ListItemText primary="Inventory (Locations)" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Card>
        </Box>
        <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, height: `calc(100vh - 120px)`, overflowY: "auto" }}
        >
            <Routes>
                <Route path="/books/*" element={<BooksIndex/>} />
                <Route path="/calendar/*" element={<CalendarIndex/>} />
                <Route path="/requests/*" element={<RequestsIndex/>} />
                <Route path="/" element={<ListIndex/>} />
                <Route path="/properties/new" element={<DetailsIndex key={"new"}/>} />
                <Route path="/properties/:id" element={<DetailsIndex/>} />

                <Route path="/integration-reservations/*" element={<IntegrationReservationIndex/>} />

                <Route path="/contacts" element={<ListContacts/>} />
                <Route path="/contacts/new" element={<DetailsContacts key={"new"}/>} />
                <Route path="/contacts/:id" element={<DetailsContacts/>} />

                <Route path="/policies" element={<ListPolicies/>} />
                <Route path="/policies/new" element={<DetailsPolicies key={"new"}/>} />
                <Route path="/policies/:id" element={<DetailsPolicies/>} />

                <Route path="/property-amenities" element={<ListAmenities/>} />
                <Route path="/property-amenities/new" element={<DetailsAmenities key={"new"}/>} />
                <Route path="/property-amenities/:id" element={<DetailsAmenities/>} />

                <Route path="/promo-codes" element={<ListPromoCodes/>} />
                <Route path="/promo-codes/new" element={<DetailsPromoCodes key={"new"}/>} />
                <Route path="/promo-codes/:id" element={<DetailsPromoCodes/>} />

                <Route path="/inventory" element={<ListInventory/>} />
                <Route path="/inventory/new" element={<DetailsInventory key={"new"}/>} />
                <Route path="/inventory/:id" element={<DetailsInventory/>} />

                <Route path="/inventory-assignments" element={<ListInventoryAssignments/>} />
                <Route path="/inventory-assignments/new" element={<DetailsInventoryAssignments key={"new"}/>} />
                <Route path="/inventory-assignments/:id" element={<DetailsInventoryAssignments/>} />

                <Route path="/inventory-location" element={<ListLocationInventory/>} />

                <Route path="/qrcode" element={<DetailsInventoryAssignments/>} />
            </Routes>
        </Box>
    </Box>;
}));
