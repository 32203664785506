import {BaseLoadingStore} from "app/modules/common/stores/BaseLoadingStore";
import {inject} from "react-ioc";
import {ApiClients} from "app/modules/main/services/AxiosBaseClient";
import {FieldState, FormState} from "formstate";
import {int, required} from "app/modules/common/form/validators";
import {action, makeObservable, observable, runInAction} from "mobx";
import {
    InventoryAssignmentDetailsModel,
    InventoryAssignmentEditModel,
    InventoryAssignmentIntervalType, InventoryAssignmentNewModel, InventoryAssignmentType,
    InventoryItemCategoryType, NamedValueLong,
    PropertySleepingArrangementBedType,
} from "app/modules/main/api/admin-clients.api";

export class DetailsStore extends BaseLoadingStore {
    @inject apiClients: ApiClients;
    inventoryShortList: NamedValueLong[] = null;

    name: string = null;

    inventoryItemId: number = null;
    inventoryItemCategoryTypes: Map<InventoryItemCategoryType, string> = new Map<InventoryItemCategoryType, string>([
        [InventoryItemCategoryType.General, "General"],
        [InventoryItemCategoryType.Bathroom, "Bathroom"],
        [InventoryItemCategoryType.Bedroom, "Bedroom"],
        [InventoryItemCategoryType.Kitchen, "Kitchen"],
        [InventoryItemCategoryType.Living, "Living"]
    ]);
    inventoryAssignmentIntervalTypes: Map<InventoryAssignmentIntervalType, string> = new Map<InventoryAssignmentIntervalType, string>([
        [InventoryAssignmentIntervalType.Day, "Day"],
        [InventoryAssignmentIntervalType.Week, "Week"],
        [InventoryAssignmentIntervalType.Month, "Month"],
        [InventoryAssignmentIntervalType.Year, "Year"]
    ]);
    inventoryAssignmentTypes: Map<InventoryAssignmentType, string> = new Map<InventoryAssignmentType, string>([
        [InventoryAssignmentType.Unit, "Unit"],
        [InventoryAssignmentType.PerGuest, "PerGuest"],
        [InventoryAssignmentType.PerBathroom, "PerBathroom"],
        [InventoryAssignmentType.PerBedType, "PerBedType"]
    ]);

    formState = new FormState({
        name: new FieldState('').validators(required),
        category: new FieldState<InventoryItemCategoryType>(InventoryItemCategoryType.General).validators(required, int),
        qty: new FieldState<number>(null).validators(required, int),
        replaceInterval: new FieldState<number>(null),
        intervalType: new FieldState<InventoryAssignmentIntervalType>(null),
        addForAllProperties: new FieldState<boolean>(false)
    });

    formNewState = new FormState({
        itemId: new FieldState<number>(null).validators(required, int),
        inventoryAssignmentsType: new FieldState<InventoryAssignmentType>(InventoryAssignmentType.Unit).validators(required),
        inventoryAssignmentsBedType: new FieldState<PropertySleepingArrangementBedType>(null),
    });

    constructor() {
        super();

        makeObservable(this, {
            inventoryItemId: observable,
            name: observable,
            inventoryShortList: observable,

            save: action,
            reload: action
        });
    }

    save = async () => {
        await this.formState.validate();

        if (this.formState.hasError) {
            return;
        }

        await this.wrapLoadingApiCall(async () => {
            await this.formState.validate();

            if (this.formState.hasError) {
                return;
            }

            if(!this.inventoryItemId) {
                await this.formNewState.validate();

                if (this.formNewState.hasError) {
                    return;
                }

                let model = new InventoryAssignmentNewModel({
                    name: this.formState.$.name.value,
                    category: this.formState.$.category.value,
                    qty: +this.formState.$.qty.value,
                    replaceInterval: this.formState.$.replaceInterval.value,
                    intervalType: this.formState.$.intervalType.value,
                    addForAllProperties: this.formState.$.addForAllProperties.value,
                    itemId: this.formNewState.$.itemId.value,
                    inventoryAssignmentsType: this.formNewState.$.inventoryAssignmentsType.value,
                    inventoryAssignmentsBedType: this.formNewState.$.inventoryAssignmentsBedType.value,
                });

                this.inventoryItemId = await this.apiClients.inventoryAssignmentClient.createInventoryAssignment(model);

                runInAction(() => {
                    this.isSavedSuccessfully = true;
                });
            } else {
                let model = new InventoryAssignmentEditModel({
                    id: this.inventoryItemId,
                    name: this.formState.$.name.value,
                    category: this.formState.$.category.value,
                    qty: +this.formState.$.qty.value,
                    replaceInterval: this.formState.$.replaceInterval.value,
                    intervalType: this.formState.$.intervalType.value,
                    addForAllProperties: this.formState.$.addForAllProperties.value,
                });

                await this.apiClients.inventoryAssignmentClient.updateInventoryAssignment(model);
                await this.reload();
            }
        });
    }

    init = async (id?: number) => {
        this.inventoryItemId = id;

        await this.wrapInitLoadingApiCall(async () => {
            this.inventoryShortList = await this.apiClients.inventoryClient.getShortList();

            if(this.inventoryItemId > 0) {
                await this.reload();
            }
        });
    }

    reload = async () => {
        let result : InventoryAssignmentDetailsModel = await this.apiClients.inventoryAssignmentClient.getDetails(this.inventoryItemId);

        runInAction(() => {
            this.inventoryItemId = result.id;

            this.name = result.name;

            this.formState.$.name.value = result.name;
            this.formState.$.category.value = result.category;
            this.formState.$.qty.value = result.qty;
            this.formState.$.replaceInterval.value = result.replaceInterval;
            this.formState.$.intervalType.value = result.intervalType;
            this.formState.$.addForAllProperties.value = result.addForAllProperties;
            this.formNewState.$.itemId.value = result.itemId;
            this.formNewState.$.inventoryAssignmentsType.value = result.inventoryAssignmentsType;
            this.formNewState.$.inventoryAssignmentsBedType.value = result.inventoryAssignmentsBedType;
        });
    }
}
